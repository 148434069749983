import * as React from 'react';
import { graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

import { FirstAppointmentQuery } from '../../graphql-types';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Section } from '../components/Section';
import SEO from '../components/seo';
import { CallMe } from '../components/CallMe';

interface FirstAppointmentProps {
  data: FirstAppointmentQuery;
}

const FirstAppointment = ({ data }: FirstAppointmentProps) => (
  <Layout>
    <SEO title="What to expect at your first appointment" />

    <Section>
      <Container>
        <h1>First appointment</h1>
        <p>
          If you are ready to work with a hearing instrument specialist who will
          take the time to properly address your hearing needs, contact us today
          to set up your first appointment.
        </p>

        <CallMe />

        <Img
          fluid={data.file?.childImageSharp?.fluid as FluidObject}
          alt="Our team meet with a patient."
          style={{ borderRadius: 8 }}
        />
      </Container>
    </Section>

    <Section>
      <Container>
        <h2>What to expect.</h2>

        <h3>1. Consultation (Before)</h3>
        <p>
          Before any testing begins, we will sit down with you and your
          communication partner to find out what your hearing difficulties have
          been like, your goals for improving, and how we can best assist you.
        </p>

        <h3>2. Video otoscopy</h3>
        <p>
          Testing begins with an otoscope that allows both you and the
          practitioner to see a clear image of the inside of your ear canal. We
          will explain what you are seeing and how it may be affecting your
          hearing.
        </p>

        <h3>3. Sound testing</h3>
        <p>
          While in our sound booth, we conduct a series of tests that measure
          your hearing thresholds at different frequencies and volumes to
          develop an audiogram mapping your particular hearing loss. Knowing
          this helps us determine if hearing aids can help correct your
          particular loss.
        </p>

        <h3>4. Speech testing</h3>
        <p>
          Understanding what others are saying is the most important goal when
          it comes to helping you. We conduct several tests aimed at finding out
          how well and how quickly you hear words and sounds.
        </p>

        <h3>5. Consultation (After)</h3>
        <p>
          After the evaluation has been completed, we will explain what the test
          results indicate, answer any questions you and your companion have and
          give you our recommendation for treatment.
        </p>

        <h3>6. Demonstration</h3>
        <p>
          If the tests show that you could benefit from hearing aids, we will
          introduce you to the latest hearing technology, program some hearing
          aids to fit your specific needs and allow you to see for yourself what
          help sounds like.
        </p>

        <h3>7. Next step</h3>
        <p>
          Based on your needs and goals, we will discuss how you would like to
          proceed. If appropriate, and you decide to get fit for hearing aids,
          we can make arrangements to begin helping you hear better.
        </p>
      </Container>
    </Section>
  </Layout>
);

export const query = graphql`
  query FirstAppointment {
    file(relativePath: { eq: "first-appointment-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1076, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default FirstAppointment;
